.modal-backdrop {
    display: none;
}

.modal.show{
    background-color: rgba(0, 0, 0, 0.25);
    padding-right: 0px !important;
}

.modal-open{
    padding-right: 0px !important;

    // .main-sidebar, .main-header, .sidebarTogglerBox {
    //     z-index: 0;
    // }
}

.modal-header .close {
    padding: 0;
    margin: 0;
}